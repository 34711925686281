import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import About from '../components/pages/about';

const seo = {
  title: 'About',
  description: 'About LiveMenu.',
  keywords: ['about'],
};

const AboutPage = () => (
  <Layout>
    <SEO
      title={seo.title}
      description={seo.description}
      keywords={seo.keywords}
    />
    <About />
  </Layout>
);

export default AboutPage;
