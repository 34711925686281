import React from 'react';
import { List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { Page } from '../layout/page';
import HeadlineSection from '../layout/headline-section';

const About = () => (
  <Page>
    <HeadlineSection
      justify='start'
      background='blue'
      title={{ lead: `Core`, emphasis: 'Values' }}
      subtitle={`We're passionate about what we build.`}>
      <List
        bordered={false}
        dataSource={[
          'Everyone should have food to eat.',
          'Eating with others is a ubiquitous human experience.',
          'Human connection is fostered by sharing experiences.',
          'Technology can help us share food and experiences.',
        ]}
        renderItem={item => (
          <List.Item className={'black'}>
            <FontAwesomeIcon className='list-item-icon white' icon={faCircle} />
            {item}
          </List.Item>
        )}
      />
    </HeadlineSection>
    <HeadlineSection
      justify='center'
      background='black'
      title={{ lead: `Our`, emphasis: 'Mission' }}
      subtitle=''>
      <h5>
        We are dedicated to building a sustainable, mutually beneficial
        ecosystem for food and drink establishments and their customers to help
        end local hunger. We will attain these goals by leveraging the best
        technology available and by setting measurable, repeatable goals that
        drive profitability and positive social impact.
      </h5>
    </HeadlineSection>
    <HeadlineSection
      justify='end'
      background='white'
      title={{ lead: `Social Impact`, emphasis: 'Manifesto' }}
      subtitle={`Our success depends on our ability to help.`}>
      <p>We were founded on the principles that:</p>
      <List
        bordered={false}
        dataSource={[
          'Achieving positive social impact is critical to our overall success.',
          'Financial success of the company is dependent on our positive social impact.',
          'Social impact of the company is dependent on our financial success.',
        ]}
        renderItem={item => (
          <List.Item className={'black'}>
            <FontAwesomeIcon className='list-item-icon blue' icon={faCircle} />
            {item}
          </List.Item>
        )}
      />
      <p>
        By design, all product and operating decisions must be filtered through
        the lens of both social impact and financial success.
      </p>
    </HeadlineSection>
  </Page>
);

export default About;
